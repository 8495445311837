import './header.css';
import React from 'react';

function logout() {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('chatMessages');
    window.location.reload();
};

const namestring = sessionStorage.getItem('Nombre');

const HeaderAdmin = () => {

    return (
        <header className="header">
            <div className="titles">
                <img 
                    src="/favicon.png" 
                    alt="favicon" 
                    className="logo" 
                />
                &nbsp;  
                <h3 className="title">Bienvenido {namestring.toUpperCase()}</h3>

                &nbsp;  
                <div className="buttons">
                    <button className="button" onClick={logout}>Salir</button>
                </div>
            </div>
        </header>
    );
};

export default HeaderAdmin;
