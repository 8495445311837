
import './functions/css/index.css';
import './functions/css/App.css';

import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import Header from './components/header';
import App from './App';

import ErrorBoundary from './functions/XX_Errorboundry.js';

import Formulario from './functions/01_Formulary.js';
import End        from './functions/02_End.js';

import MS         from './functions/00_MS.js';

const root = ReactDOM.createRoot(document.getElementById('root'));



if (!sessionStorage.getItem('token')){
  root.render(
    <React.StrictMode>
      <ErrorBoundary>
        <Router>
          <Routes>
              <Route path="/"                   element={<App/>} />  
              <Route path="/MS"                 element={<MS/>} /> 
          </Routes>
        </Router>
      </ErrorBoundary>
    </React.StrictMode>
  )
}
else{
  root.render(
    <React.StrictMode>
      <ErrorBoundary>
        <Router>
          <Header/>
          <Routes>

              <Route path="/MS"                 element={<MS/>} /> 
              <Route path="/"                   element={<Formulario/>} />
              <Route path="/End"                   element={<End/>} />  
        
          </Routes>
        </Router>
      </ErrorBoundary>
    </React.StrictMode>
  );
};

reportWebVitals();
