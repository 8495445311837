import './css/App.css';
import React from 'react';

export default function End() {

  return (
    <div className="BackGround">
      <div className="MainBody" style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', padding: '20px' }}>
        <h1>
          {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
          🎉 ¡Gracias por participar en nuestro proceso de preselección! 🎉
        </h1>
        <h2>
          Valoramos mucho el tiempo y el esfuerzo que dedicaste para completar este formulario.
        </h2>
        <h2>
          Una vez que hayamos terminado de evaluar todas las respuestas, te estaremos notificando los resultados y los próximos pasos a seguir. Te pedimos paciencia mientras avanzamos con esta etapa.
        </h2>
        <h2>
          Si tienes cualquier duda o necesitas más información, no dudes en contactarnos a través de nuestro correo electrónico: 
          <strong> contacto@renee.cl </strong>.
        </h2>
        <h2>
          {/* // eslint-disable-next-line */}
          Te deseamos lo mejor en este proceso y un excelente día. ¡Gracias nuevamente por confiar en nosotros! 🌟
        </h2>
      </div>
    </div>
  );
  
}
