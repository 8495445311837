import './css/App.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import APICALL from './APICALL';

export default function Formulario() {

  const navigate = useNavigate();
  const [Form, setForm] = useState([]);

  const handleInputChange = (index, field, value) => {
    const updatedForm = [...Form];
    updatedForm[index][field] = value;
    setForm(updatedForm);
  };

  const handleCheckboxChange = (blockIndex, optionIndex, isChecked) => {
    setForm(prevForm => {
      const updatedForm = [...prevForm];
      updatedForm[blockIndex].alternatives[optionIndex].selected = isChecked;
      return updatedForm;
    });
  };

  const handleRadioChange = (blockIndex, selectedOptionIndex) => {
    setForm(prevForm => {
      const updatedForm = [...prevForm];
      updatedForm[blockIndex].alternatives = updatedForm[blockIndex].alternatives.map((alt, optIndex) => ({
        ...alt,
        selected: optIndex === selectedOptionIndex
      }));
      return updatedForm;
    });
  };

  

  const handlesubbmit = async () => {
    await APICALL({
      apicall: 'FM_submmit',
      code: sessionStorage.getItem("process"),
      U_emp: sessionStorage.getItem('Grupo'),
      FormID: sessionStorage.getItem('FID') ,
      Name: sessionStorage.getItem('Nombre'),
      Formreply: Form
    });

    navigate('/End') ;  
  };

  useEffect(() => {
    const fetchData = async () => {

      const data2 = await APICALL({
        apicall: 'FM_fetch',
        code: sessionStorage.getItem("process"),
        U_emp: sessionStorage.getItem('Grupo'),
        FormID: sessionStorage.getItem('FID')
      });

      setForm(data2);
    };

    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="BackGround">
      <div className="typing-effect-container">
        <h2 className="typing-effect">
        {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
        🎉 Felicitaciones! has pasado a la fase de evaluacion para el cargo [ingresar nombre del proceso], por favor completa este formulario de evaluación 🎉
        </h2>
      </div>
      <div className='MainBody'>
        <div style={{ overflow: 'scroll', width:'98%' }}>
          <div>
          {Form.length > 0 && (
            <div className='Formbody'>
              {Form.map((block, index) => (
                <div
                  key={index}
                  className='notas'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: '1vh',
                    marginRight: '2vh',
                    gap: '1vh',
                    overflow:'hidden',
                  }}
                >
                  <div style={{ flex: 1 }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span
                        className='notas-title'
                        style={{ marginLeft: '1vh', fontWeight: 'bold' }}
                      >
                        {block.title}
                      </span>
                    </div>

                    {block.type === 'Texto' ? (
                      <textarea
                        value={block.content}
                        className='notas'
                        onChange={(e) => handleInputChange(index, 'content', e.target.value)}
                        placeholder="Editar contenido"
                        rows="5"
                        style={{ height: '10vh', marginBottom: '1vh' }}
                      />
                    ) : block.type === 'Alt Unica' ? (
                      <div className="alternatives-container">
                        {block.alternatives && block.alternatives.map((alternative, optIndex) => (
                          <label key={optIndex} className={ alternative.selected ? 'alternative-label blue' : 'alternative-label' }>
                            <input
                              type="radio"
                              className="alternative-checkbox"
                              checked={alternative.selected || false}
                              onChange={(e) => handleRadioChange(index, optIndex, e.target.checked)}
                            />
                            {alternative.label}
                          </label>
                        ))}
                      </div>
                    ) : block.type === 'Alt Multiple' ? (
                      <div className="alternatives-container">
                        {block.alternatives && block.alternatives.map((alternative, optIndex) => (
                          <label key={optIndex} className={ alternative.selected ? 'alternative-label blue' : 'alternative-label' }>
                            <input
                              type="checkbox"
                              className="alternative-checkbox"
                              checked={alternative.selected || false}
                              onChange={(e) => handleCheckboxChange(index, optIndex, e.target.checked)}
                            />
                            {alternative.label}
                          </label>
                        ))}
                      </div>
                    ) : null}
                    
                  </div>
                </div>
              ))}
            </div>
          )}
          </div>
      </div>
      </div>
      <div className='bottom'>
        <div></div>
        <button style={{padding:'2vh'}} onClick={handlesubbmit}>Envia tus Respuestas</button>
        <div></div>
      </div>
    </div>
  );
}
