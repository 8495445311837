
//=============================================================================
// Pantalla Login
//=============================================================================

import './css/App.css';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Login( ) {
  
  const navigate = useNavigate();

  let count                     = 0;
  

  const HandleLoggin = (nombre , procesoId) => {

    sessionStorage.setItem('Nombre', nombre);
    sessionStorage.setItem('FID', procesoId);
    sessionStorage.setItem('token', nombre+procesoId);
    window.location.reload();

  };

  const GotoMS = () => {
    if (count === 5){
      navigate('/MS'); 
    } else {
      count = count+1;
    }
	};

  useEffect(() => {

    const params = new URLSearchParams(window.location.search);
    const nombre = params.get("NM");
    const procesoId = params.get("PID");

    if (nombre && procesoId) {
      HandleLoggin( nombre, procesoId )
    } else {
      navigate("/error");
    }
  }, [navigate]);

  return(
    <main className="loggin"> 
      <img 
        src="/favicon.png" 
        alt="favicon" 
        className="logo" 
        onClick={GotoMS}
      />
      <div className='MainBody'>
        <div className='boxlog'>
          <h2>Bienvenido a Candidatos!</h2>
          {/* <form onSubmit={HandleLoggin}>
        
            <input type="text" placeholder='Nombre' required
            onChange={e => setUserName(e.target.value)} />
        
            <input type="text" placeholder='Rut' required
            onChange={e => setRUT(e.target.value)} />

            <input type="text" placeholder='FID' required
            onChange={e => setFID(e.target.value)} />
        
            <button type="submit">Iniciar</button>
              
          </form> */}
        </div>
      </div>
    </main>
  );
}

